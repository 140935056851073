import logo from './logo.svg';
import './App.css';
import Stopwatch from './Components/Stopwatch';

function App() {
  return (
    <Stopwatch/>
  );
}

export default App;
